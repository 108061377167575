import activity from './m-success-team.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Success Team',
  icon: 'mdi-account-multiple',
  description: 'Meet with your Success Team!',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#ae90b0'
  },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-success-team',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        channels: []
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
